.RightSide {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.navIcons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.navIcons > img {
  width: 1.5rem;
  height: 1.5rem;
}

.r-btn {
  height: 3rem;
  width: 80%;
  align-self: center;
}
