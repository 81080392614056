.PostShare {
  display: flex;
  gap: 1rem;
  background-color: var(--cardColor);
  padding: 1rem;
  border-radius: 1rem;
}

.PostShare > img {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}

.PostShare > div {
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 1rem;
}

.PostShare > div > input {
  background-color: var(--inputColor);
  border-radius: 10px;
  padding: 10px;
  font-size: 17px;
  border: none;
  outline: none;
}

.postOptions {
  display: flex;
  justify-content: space-around;
}

.option {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.option:hover {
  cursor: pointer;
}

.ps-btn {
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
}

.previewImage {
  position: relative;
}

.previewImage > svg {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
  z-index: 2;
}

.previewImage > img {
  width: 100%;
  max-height: 20rem;
  object-fit: cover;
  border-radius: 0.5rem;
}
